.multi__range{
  display: flex;
  flex-direction: column;
  gap: 12px;
  // height: 85px;
}
.inputs__wrapper{
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 0 2px;
}

.container {
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

}


.text__input{
  max-width: 17px;
  max-height: 28px;
  border-radius: 8px;
  background-color: var(--surface-front);
  box-shadow: 0 0 0 1px var(--gray-100);
  padding: 4px 12px;
  text-align: center;

  font-size: 16px;
  line-height: 20px;
}
.styles.text__input_right{

}
.styles.text__input_left{

}

.slider {
  width: 100%;
}
.slider__marks_info{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value {
  position: absolute;
}

.slider__track,
.slider__range {
  height: 4px;
  border-radius: 2px;
}

.slider__track {
  background: var(--gray-100);
  width: 100%;
  z-index: 1;
}

.slider__range {
  background: linear-gradient(90deg, #CB3100 0%, #5CD000 100%);
  z-index: 2;
}

.slider__left-value,
.slider__right-value {
  color: #dee2e6;
  font-size: 12px;
  margin-top: 20px;
}

.slider__left-value {
  left: 6px;
}

.slider__right-value {
  right: 6px;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 100%;
  outline: none;
  top: -2px;
}


/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 0px 2px #FFFFFF;
  cursor: pointer;
  height: 20px;
  width: 20px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 0px 2px #FFFFFF;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

.thumb--left {
  z-index: 3;
}


.thumb--right::-webkit-slider-thumb {
  background-color: #5DD001;
}

.thumb--left::-webkit-slider-thumb{
  background-color: #C53B01;
}

.thumb--right::-moz-range-thumb {
  background-color: #5DD001;
}

.thumb--left::-moz-range-thumb {
  background-color: #C53B01;
}

.thumb--right {
  z-index: 4;
}


