@import 'oelp-tokens/css/variables';

.object-info-card {
  display: flex;
  flex-direction: column;
  padding: 16px;
  // padding: 16px 16px 12px;
  margin: 0 20px;
  background: $surface-front;
  border-radius: 8px;

  // gap: 10px;
  transition: all .12s ease-out;

  &-heading {
    display: flex;
    margin-bottom: 8px;
    color: $gray-400;
    font-family: var(--subheader-desktop-weight-3-font-family);
    font-size: var(--subheader-desktop-weight-3-font-size);
    //font-weight: var(--subheader-desktop-weight-3-font-weight);
    line-height: var(--subheader-desktop-weight-3-line-height);
  }

  &-content {
    // margin-top: 8px;
    // margin-top: 8px;
    display: flex;
    //margin-bottom: 20px;
    //margin-top: 8px;
    width: 100%;
  }
  &-footer {
    margin-top: 16px;
    display: flex;
    //margin-bottom: 20px;
  }
}
