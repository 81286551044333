@import 'oelp-tokens/css/variables';

.status-switcher {
  display: flex;
  margin-top: 28px;
  background: $surface-front;
  border-radius: 8px;
}
.status-loading {
  padding: 14px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}