@import 'oelp-tokens/css/variables';


.container {
    position: absolute;
     left: 0;
    // right: 0;
     top: 0;
    // bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    background-color: $gray-50;
    z-index: 2001;
    padding: 20px;
    overflow: auto;

    cursor: default;

    .editor {
        width: 100%;
        height: 80%;
    
        & > div {
            height: 100%;
    
            padding: 20px;

            cursor: text;
            
            & > div {
                height: 100%;
    
                & > p {
                    height: 100%;
                }
            }
        }
    }
}

.editBarWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: flex-start;
}