@import 'oelp-tokens/css/variables';

.editor-menu{
  transform: translateY(-160%)!important;
}

.editor-menu-content{
  position: absolute;
  background: $surface-front;
  border-radius: 8px;
  bottom: 50px;

  &-switch {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    padding-right: 16px;
  }

}

.editor-menu-wrapper {
  // position: relative;
  // z-index: 50;
  // bottom: 100px;
  // max-width: 250px;
  // left: 24px;
  .editor-menu-trigger-wrapper{
    // position: absolute;

  }
}
