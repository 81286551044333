@import 'oelp-tokens/css/variables';

.sidebar {
  display: flex;
  flex-direction: column;
  width: 100%;
  // padding: 20px;
  // max-width: 536px;
  // max-width: 536px;
  //width: 45%;
  gap: 16px;
  //overflow-y: auto;
  @media all and (max-width: 785px) {
    max-width: 100%;
  }

  .object-info-content-header {
    margin-bottom: 8px;
    color: var(--gray-400);
    font-size: var(--subheader-desktop-weight-1-font-size);
    font-weight: var(--subheader-desktop-weight-1-font-weight);
    line-height: var(--subheader-desktop-weight-1-line-height);
  }

  .icon {
    font-size: 22px !important;
  }

  .fill-width-element {
    padding: 16px 20px;
  }

  .fill-width-bg-element {
    padding: 16px 20px;
    background-color: $surface-front;
  }

  .info-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .bg-element {
    display: flex;
    flex-direction: column;
    margin: 0 20px;
    padding: 16px;
    background: $surface-front;
    border-radius: 8px;
    gap: 8px;
  }

  .buttons-container {
    display: flex;
    gap: 8px;
    margin: 0 20px;
  }

  &-header {
    position: relative;
    display: flex;
    flex-direction: column;

    &-top {
      display: flex;
      position: relative;
      flex-flow: column nowrap;
      justify-content: flex-start;
      // justify-content: space-around;
      align-items: flex-start;
      // margin-bottom: 16px;
      min-height: 5.2rem;

      &-close-button {
        padding: 0 !important;
        // position: absolute;
        // right: 0;
        // top: 0;
      }

      &-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        &-text {
          display: flex;
          justify-content: center;
          // min-height: 2rem;
          // display: -webkit-box;
          // -webkit-line-clamp: 2;
          // -webkit-box-orient: vertical;
          overflow: hidden;
          max-width: 380px;
          // max-height: 44px;
          color: $gray-900;
          // font-family: var(--title-desktop-h4-weight-2-font-family);
          // font-size: var(--title-desktop-h4-weight-2-font-size);
          // font-weight: var(--title-desktop-h4-weight-2-font-weight);
          // line-height: var(--title-desktop-h4-weight-2-line-height);
          transition: all 0.5s;
        }
      }

      &-subtitle {
        display: flex;
        flex-direction: row;
        // margin-top: 6px;

        &-text {
          // display: flex;
          // justify-content: center;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          // min-height: 2rem;
          overflow: hidden;
          max-width: 380px;
          max-height: 2rem;
          vertical-align: middle;
          color: $gray-500;
          // font-family: $font-families-main;
          // font-size: $font-size-x3-5;
          // font-weight: $font-weights-medium;
          // line-height: $line-heights-x4;
        }
      }

      &-title-text,
      &-subtitle-text {
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;

        word-break: break-word;
      }
    }
  }

  &-button-container {
    display: flex;
    gap: 8px;

    padding: 0 20px;
    // margin: 26px 0 4px;
  }
}

.unselected-button {
  color: $gray-600 !important;
}

.editorMode {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
  max-height: 550px;
}

.sidebar-content {
  display: flex;
  flex-direction: column;
  // gap: 16px;
  //min-height: 100%;
}

.image {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  // margin-bottom: 16px;

  width: 100%;
  height: 200px;

  padding: 0 20px;

  border-radius: 8px;

  overflow: hidden;

  > img {
    max-height: 100%;
    max-width: 100%;
    border-radius: 8px;
  }

  &-go-back-button {
    position: absolute;
    top: 8px;
    left: 32px;
  }
}

.sidebarBody {
  padding: 16px 0;
  background: $surface-mid;
  width: 100%;
  overflow-x: hidden;
  //min-height: 100%;
}

.description__charity {
  display: flex;
  flex-direction: column;
  gap: 40px;
  &_title {
    color: var(--gray-400);
  }

  &_body {
    display: flex;
    flex-direction: column;
    gap: 12px;
    &-item {
      display: flex;
      gap: 8px;
      align-items: center;
      margin-top: 8px;
      &_name {
        color: var(--gray-700);
      }

      &_descr {
        color: var(--gray-600);
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.24px;
        display: -webkit-box;
        -webkit-line-clamp: 1; // количество строк
        -webkit-box-orient: vertical;
        overflow: hidden;

        &_a {
          color: #689aff;

          cursor: pointer;

          transition: all 0.3s ease-out;

          &:hover {
            text-decoration: underline;
          }
        }
      }
      &_p {
        color: var(--gray-800);
        font-family: var(--paragraph-desktop-x3-5-weight-1-font-family);
        font-size: var(--paragraph-desktop-x3-5-weight-1-font-size);
        font-weight: var(--paragraph-desktop-x3-5-weight-1-font-weight);
        line-height: var(--paragraph-desktop-x3-5-weight-1-line-height);
      }
    }
  }
}
