@import 'oelp-tokens/css/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 6.29px;
  .search-results-wrapper{
    display: flex;
    flex-direction: column;
    gap: 6.29px;
    overflow: hidden;
    overflow-y: auto;
    max-height: 416px;
  }
}