@import 'oelp-tokens/css/variables';

.dropdown {
    &-content {
        position: absolute;
        z-index: 5;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: min-content;
        min-width: 140px;
        min-width: min-content;
        max-height: 70vh;
        //max-width: 100%;
        min-height: min-content;
        //padding: 8px 10px;
        overflow: hidden;
        overflow-y: auto;
        font-size: $font-size-x4;
        line-height: 18px;
        letter-spacing: -0.28px;
        background: $surface-front;
        border-radius: 8px;
        visibility: visible;
        box-shadow: 0px 2px 12px rgba(2, 8, 32, 0.12);
        opacity: 1;
        //filter: drop-shadow($shadow-active-type);
        transition: all 0.12s ease-out;
        top: 112%;
        right: 80px;
        &.top {
            transform: translateX(-50%);
        }
        &.bottom {
            transform: translateX(-50%);
        }
    }
    &-header {
        width: 100%;
        padding: 10px 13px 5px;
        text-align: left;
    }
    &-body,
    &-footer {
        width: 100%;
    }
    &-wrapper {
        position: relative;
    }
}
