@import 'oelp-tokens/css/variables';

.media-page-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  .content-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 100vw;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .media-page-content {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    .images-gallery-container {
      width: 100%;
      height: 100%;
      padding-top: 80px;
    }
  }

  .media-page-sidebar {
    display: none;
    animation: media-page-sidebar-translate 0.12s ease-in-out;
  }
  .media-page-sidebar-active {
    display: block;
    position: absolute;
    height: 100%;
    left: 70vw;
    animation: media-page-sidebar-translate 0.12s ease-in-out;
    z-index: 10;
    width: 100%;
  }

  @keyframes media-page-sidebar-translate {
    from {
      transform: translate(100%, 0);
    }
    to {
      ttransform: translate(0, 0);
    }
  }
}
