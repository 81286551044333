.container {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 0;
  padding: 12px 16px 0;
  gap: 8px;
  box-sizing: border-box;

  transition: all .12s ease-out;
  outline: none!important;

  background-color: var(--surface-front);

  &-title {

    &-rating {

    }
  }

  &-body {
    margin: 0;
  }

  &::before {
    display: none;
    outline: none!important;
  }
}
