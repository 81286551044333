@import 'oelp-tokens/css/variables';

.container {
  display: flex;
  align-items: center;
  gap: 6.29px;
  color: $gray-400;
  max-width: fit-content;
  width: 100%;
  margin-bottom: 13px;
  .title{
    font-family: var(--caption-desktop-x3-25-weight-3-font-family);
    font-weight: var(--caption-desktop-x3-25-weight-3-font-weight);
    line-height: var(--caption-desktop-x3-25-weight-3-line-height);
    font-size: var(--caption-desktop-x3-25-weight-3-font-size);
    letter-spacing: var(--caption-desktop-x3-25-weight-3-letter-spacing);
    text-decoration: var(--caption-desktop-x3-25-weight-3-text-decoration);
  }
}
