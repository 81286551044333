@import 'oelp-tokens/css/variables';

.coords-panel {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  top: auto;
  bottom: 36px;
  right: 16px;

  &-header {
    margin-bottom: 16px;
    padding: 1px;
    width: 100%;

    &-btn-box {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      button {
        margin-left: 12px;
        border-radius: 8px;
        padding: 6px 12px 8px;
        font-weight: normal;
        font-size: 16px;
        line-height: 18px;
        border: none;
      }
    }

    &-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-family: var(--title-desktop-h4-weight-1-font-family);
      font-weight: var(--title-desktop-h4-weight-1-font-weight);
      font-size: var(--title-desktop-h4-weight-1-font-size);
      line-height: var(--title-desktop-h4-weight-1-line-height);
      margin-bottom: 12px;
    }

    &-close_btn {
      background-color: $heat-status-red !important;
    }
  }

  &-input {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-family: var(--title-desktop-h4-weight-3-font-family);
    font-size: var(--title-desktop-h4-weight-3-font-size);
    font-weight: var(--title-desktop-h4-weight-3-font-weight);
    line-height: var(--title-desktop-h4-weight-4-line-height);
    border: 1px solid #bdbdbd;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  &-save {
      width: 100%;
      display: flex;
      justify-content: flex-end;
  }


  @media all and (min-width: 768px) {
    top: 20px;
    bottom: auto;
    align-items: flex-start;

  }

  @media all and (min-width: 1300px) {
    top: 370px;
    bottom: auto;
  }

  &-container {
    position: relative;
    margin-top: 0px;
    top: 0;
    right: 0px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: flex-start;
    justify-content:flex-start;
    order: 1;
    min-width: 80px;
    min-height: 80px;
    padding: 20px 12px;
    background: $surface-front;
    border-radius: 8px;
    user-select: none;
  }

  &-text {
    font-weight:normal;
    font-size: 16px;
    line-height: 18px;
  }

  &-mobile-header{
    padding: 20px;
  }
}




