.container {
  position: relative;
  z-index: 0;
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
}

.map-wrapper {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  .images-gallery-container{
    position: absolute;
    display: flex;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 80px;
  }
}

.map-view {
  width: 100%;
  height: 100%;
  
  &.editor-cursor-enabled{
      cursor:crosshair;
  }
  .bottom-left-controls {
    position: absolute;
    bottom: 16px;
    left: 16px;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: flex-start;


      @media all and (min-width: 785px) {
        bottom: 40px;
        left: 40px;
      }
  }
}