@import 'oelp-tokens/css/variables';

.container {
  $transition-duration: 0.12s;

  border-radius: 8px;

  background-color: var(--surface-front);

  transition: all $transition-duration ease-in;

  &-toggle {
    position: relative;

    margin: 0;
    min-height: 56px;

    border-radius: 8px;

    overflow: hidden;

    transition: all $transition-duration ease-in;

    // background: linear-gradient(
    //   90deg,
    //   rgba(250, 250, 250, 1) 0%,
    //   rgba(104, 154, 255, 1) 100%
    // );
    background: linear-gradient(
      90deg,
      rgba(250, 250, 250, 1) 0%,
      rgba(250, 250, 250, 1) 30%,
      rgba(180, 205, 255, 1) 36%,
      rgba(180, 205, 255, 1) 63%,
      rgba(104, 154, 255, 1) 69%,
      rgba(104, 154, 255, 1) 100%
    );
    background-size: 324%;
    background-position-x: 0px;

    cursor: pointer;

    &-icon {
      position: absolute;

      &-gazprom {
        position: absolute;
        bottom: 10px;
        left: 118px;

        fill: var(--gray-unchangeable-900);

        transition: all $transition-duration ease-in;

        &-active {
          left: 24px;
          fill: var(--gray-unchangeable-0);
        }
      }

      &-sun {
        position: absolute;

        right: -70px;
        bottom: -70px;

        transition: all 0.12s ease-in;

        &:hover {
          right: 126px;
          bottom: -5px;
        }

        &-active {
          right: 126px;
          bottom: -3px;
        }
      }
    }

    &:hover {
      background-position-x: 50%;

      box-shadow: 0px 4px 4px rgba(210, 225, 35, 0.25);

      .container-toggle-icon-gazprom {
        left: 24px;
      }

      .container-toggle-icon-sun {
        right: 126px;
        bottom: -3px;
      }
    }

    &-opened {
      background-position-x: 100%;

      &:hover {
        background-position-x: 100%;

        box-shadow: none;
      }
    }
  }

  &-list {
    margin-bottom: 0;
    margin-top: 0;
    max-height: 0;
    padding-left: 1rem;
    overflow: hidden;

    transition: all 0.5s ease-out;

    &_opened {
      margin: 8px 0 8px;
      max-height: 800px;
    }

    &-item {
      & .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #eee;
      }

      & .label {
        & > .input:checked ~ .checkmark {
          background-color: #2196f3;
        }

        & .checkmark:after {
          content: '';
          position: absolute;
          display: none;
        }

        & .input:checked ~ .checkmark:after {
          display: block;
        }

        &:hover .input ~ .checkmark {
          background-color: #ccc;
        }
      }
    }
  }
}

.container {
  &-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0;
    padding: 12px 12px 12px 20px;
    font-family: var(--button-desktop-lite-font-family);
    font-weight: var(--button-desktop-lite-font-weight);
    line-height: var(--button-desktop-lite-line-height);
    font-size: var(--button-desktop-lite-font-size);

    border-radius: 8px;

    cursor: pointer;

    &:hover {
      background-color: $interactive-light-hover;
    }

    &-arrow {
      transition: all 0.3s ease-out;
      transform: rotate(0);

      &_opened {
        transform: rotate(0.5turn);
      }
    }
  }

  &-list {
    margin-bottom: 0;
    margin-top: 0;
    max-height: 0;
    padding-left: 0;
    overflow: hidden;

    transition: all 0.5s ease-out;

    &_opened {
      margin-top: 8px;
      max-height: 800px;
    }
  }
}
