@import 'oelp-tokens/css/variables';

.object-info-box {
  // padding-left: 16px;
  // margin: 0 20px;
  padding: 16px;
  // padding: 4px 16px 16px 16px;
  border-radius: 8px;
  &-content {
    max-height: 120px;
    &-text {
      p {
        margin: 0;
        padding: 0;
      }
      font-family: var(--paragraph-desktop-x3-5-weight-1-font-family);
      font-size: var(--paragraph-desktop-x3-5-weight-1-font-size);
      font-weight: var(--paragraph-desktop-x3-5-weight-1-font-weight);
      line-height: var(--paragraph-desktop-x3-5-weight-1-line-height);
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    &-text-open {
      -webkit-line-clamp: unset;
    }
  }
}
