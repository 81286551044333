@import 'oelp-tokens/css/variables';


i {
  /* use !important to prevent issues with browser extensions that change fonts */
  transition: all 0.12s ease-out;

  &.icon-tiny {
    font-size: 20px !important;
    font-weight: $font-weights-medium;
  }

  &.icon-low {
    font-size: 24px !important;
    font-weight: $font-weights-medium;
  }

  &.icon-middle {
    font-size: 28px !important;
    font-weight: $font-weights-medium;
  }

  &.icon-big {
    font-size: 32px !important;
    font-weight: $font-weights-medium;
  }
}