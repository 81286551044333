@import 'oelp-tokens/css/variables';

.card {


  &-container {
    display: flex;
    // justify-content: center;
    gap: 8px;
    width: 100%;
    padding: 0 16px 16px 16px;
  }

  &-buttons-container {
      display: flex;
      justify-content: center;
      gap: 8px;
      width: 100%;
      padding: 0 16px;
      // padding: 0 16px 16px 16px
  }

  justify-content: space-between;
  &-header {
    // position: relative;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    width: 100%;
    position: relative;
    height: 5.9rem;
    // padding: 16px;
    padding: 16px 16px 0;
    // background: $surface-front;

    &-tooltip {
      width: 90%;
    }

    &-header, &-subheader {
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;

      word-break: break-word;
    }

    span {
      font-size: var(--caption-desktop-x3-weight-3-font-size);
    }
  }
  &-footer {
    // position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    padding: 16px 16px 0;
    // background: $surface-front;
  }
  
  margin-top: 16px;
  padding: 26px 16px 24px;
  background: $surface-front;
  border-radius: 8px;

  & div {
    width: 100%;
  }

  &-empty-photo-list {
    width: 100%;
    height: 204px;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  &-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    gap: 8px
  }

  &-heading {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 0 16px;


    &-text {
      display: flex;
      justify-content: space-between;
      width: 100%;
      font-family: var(--text-desktop-x3-5-weight-2-font-family);
      font-size: var(--text-desktop-x3-5-weight-2-font-size);
      font-weight: var(--text-desktop-x3-5-weight-2-font-weight);
      line-height: var(--text-desktop-x3-5-weight-2-line-height);

      &-title {
        max-width: 340px;
        min-width: 270px;
        max-height: 47px;;
        text-overflow: ellipsis;
      }
      // margin-left: 8px;
      h4 {
        display: inline-block;
        max-width: 340px;
        min-width: 270px;
        text-overflow: ellipsis;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        padding-right: 10px;
      }
    }

    &-full-title {
        display: flex;
        margin: 16px 0 -13px 0;
        min-height: 24px;
        align-items: center;
        color: $interactive-default;
  
        &-name {
          display: flex;
          align-items: center;
        //   margin-left: 5px;
          cursor: pointer;
  
          &:hover .object-header-text {
            display: flex;
          }
        }
  
        i {
        //   margin: 0 2px;
          cursor: pointer;
        }
      }
  }
}
