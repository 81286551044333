@import 'oelp-tokens/css/variables';

.login {
    &-logo {
        position: relative;
        z-index: 20;
        display: flex;
        align-items: flex-end;
        justify-content: center;

        @media all and (min-width: 785px) {
            margin-bottom: 64px;
        }

        &-text {
            color: $interactive-default;
            font-weight: $font-weights-semi-bold;
            font-size: $font-size-x4;
            font-family: $font-families-main;
            font-style: normal;
            line-height: $line-heights-x5;
            letter-spacing: $letter-spacing-m0-24;
            margin-left: 12px;

            @media all and (min-width: 785px) {
                color: $surface-mid;
                font-size: $font-size-x8;
                line-height: $line-heights-x7;
                margin-left: 11.51px;
            }
        }
    }

    &-form {
        position: relative;
        z-index: 20;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        height: 100%;
        padding: 32px 16px 36px;

        @media all and (min-width: 785px) {
            max-width: 540px;
            height: auto;
            padding: 50px 56px 70px;
            background: $surface-front;
            border-radius: 24px;
            box-shadow: -8px 2px 28px rgba(2, 8, 32, 0.2);
        }

        & > div {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 10px
        }
        &-button {
            display: flex;
            justify-content: center;
            align-items: center;
            max-height: 48px;
            min-width: fit-content;
            // box-sizing: 100%;
            box-sizing: border-box;
            width: 100%;
            // width: fit-content;
            padding: 12px;
            color: $gray-0;
            font-weight: var(--button-desktop-default-font-weight);
            font-size: var(--button-desktop-default-font-size);
            font-family: var(--button-desktop-default-font-family);
            font-style: normal;
            line-height: var(--button-desktop-default-line-height);
            letter-spacing: $letter-spacing-m0-28;
            background: $interactive-default;
            text-decoration: var(--button-desktop-default-text-decoration);
            border: 1px solid transparent;
            cursor: pointer;
            transition: all 0.12s ease-out;
            gap: 8px;
            background: $interactive-default;
            border-radius: 8px;
            &:hover, &:active, &.active{
                background: $interactive-hover;
                color: $gray-0;
            }
            &:disabled{
                opacity: 0.25;
                &:hover{
                    background: $interactive-default;
                }
            }
        }


        &-logo {
            width: 100%;
            height: 72px;
            display: flex;
            object-fit: contain;

            @media all and (min-width: 785px) {
                //display: none;
            }
        }
        &-error {
            color: $validation-error-main;
        }
    }


}
