@import 'oelp-tokens/css/variables';

.snack-bar {

  &-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: 240px;
    background: $surface-dark-blackout;
    border-radius: 8px;
    box-shadow: 0 2px 12px rgba(2, 8, 32, 0.12);
    bottom: 40px;
    padding: 7px 16px 7px 17px;

    &-timer {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #9747FF;
      font-size: var(--text-desktop-x4-weight-3-font-size);

      &-value {
        color: #9747FF;
      }
    }

    &-title {
      color: $gray-0;
      margin-left: 9px;
      font-family: var(--text-desktop-x4-weight-3-font-family);
      font-size: var(--text-desktop-x4-weight-3-font-size);
      font-weight: var(--text-desktop-x4-weight-3-font-weight);
      line-height: var(--text-desktop-x4-weight-3-line-height);
    }

    &-button {
      max-width: max-content;
      color: $gray-0 !important;
      margin-left: 16px;
      border: 1px solid $gray-0;
    }
  }
}
