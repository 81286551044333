@import "oelp-tokens/css/variables";

html,
body,
.leaflet-container,
.leaflet {
    font-family: $font-families-main !important;
}
html {
    height: 100%;
    //height: -webkit-fill-available;
}
body {
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 100%;
    height: 100%;

    /* mobile viewport bug fix */
    //min-height: -webkit-fill-available;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -ms-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
    scrollbar-width: thin;
}
a {
    text-decoration: none;
}
ul, ol {
    list-style: none;
}

textarea::-webkit-scrollbar {
    width: 5px;
    height: 7px;
    background-color: transparent;
    pointer-events: none;
}

textarea::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 5px;
    transition: all .12s ease-out;
}

textarea::-webkit-scrollbar-thumb {
    box-shadow: inset 0px 2px 12px rgba(5, 18, 70, 0.5);
    background-color: transparent;

    border-radius: 5px;

    transition: all .12s ease-out;
    &:hover{
        background: $gray-200;
    }
}

nav::-webkit-scrollbar {
    width: 5px;
    height: 7px;
    background-color: transparent;
    pointer-events: none;
}

nav::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 5px;
    transition: all .12s ease-out;
}

nav::-webkit-scrollbar-thumb {
    box-shadow: inset 0px 2px 12px rgba(5, 18, 70, 0.5);
    background-color: transparent;

    border-radius: 5px;

    transition: all .12s ease-out;
    &:hover{
        background: $gray-200;
    }
}

div::-webkit-scrollbar {
    width: 5px;
    height: 7px;
    background-color: transparent;
    pointer-events: none;
}

div::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 5px;
    transition: all .12s ease-out;
}

div::-webkit-scrollbar-thumb {
    box-shadow: inset 0px 2px 12px rgba(5, 18, 70, 0.5);
    background-color: transparent;

    border-radius: 5px;

    transition: all .12s ease-out;
    &:hover{
        background: $gray-200;
    }
}



//div::-webkit-scrollbar-track {
//  box-shadow: inset 0 0 2px $gray-500;
//  border-radius: 5px;
//}
//
//div::-webkit-scrollbar-thumb {
//  box-shadow: inset 0 0 2px $gray-800;
//  background: $gray-200;
//  border-radius: 5px;
//
//  transition: all .12s ease-out;
//}
//
//div::-webkit-scrollbar-thumb:hover {
//    background: $gray-300;
//  }

#root {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: flex;
    width: 100%;
    height: 100%;
    //height: -webkit-fill-available;
    min-height: 100%;
    //min-height: -webkit-fill-available;
    overflow: hidden;
}
.main-wrapper {
    position: relative;
    z-index: 0;
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
}
.main-content {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
}
.logo-object {
    position: absolute;
    right: 0;
    bottom: 16px;
    left: 0;
    z-index: 999;
    width: 58px;
    height: 40px;
    margin: 0 auto;
    object-fit: contain;
    cursor: pointer;
    @media all and (min-width: 785px) {
   //     right: 156px;
   //     bottom: 40px;
        bottom: 20px;
        right: 40px;
        left: unset;
        width: 117px;
        height: 57px;
        margin: 0;
    }
}
