@import 'oelp-tokens/css/variables';


.ProseMirror {
  outline: none;

    table {
      border-collapse: collapse;
      margin: 0;
      overflow: hidden;
      table-layout: fixed;
      width: 100%;
      background-color: $surface-back;
      border-radius: 8px;
  
      td,
      th {
        border: 2px solid #ced4da;
        box-sizing: border-box;
        min-width: 1em;
        padding: 3px 5px;
        position: relative;
        vertical-align: top;
  
        > * {
          margin-bottom: 0;
        }
      }
  
      th {
        // background-color: #f1f3f5;
        font-weight: bold;
        text-align: left;
      }

      td {
        vertical-align: baseline;
      }
  
      .selectedCell:after {
        background: rgba(200, 200, 255, 0.4);
        content: "";
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        pointer-events: none;
        position: absolute;
        z-index: 2;
      }
  
      .column-resize-handle {
        background-color: #adf;
        bottom: -2px;
        position: absolute;
        right: -2px;
        pointer-events: none;
        top: 0;
        width: 4px;
      }
  
      p {
        margin: 0;
      }
    }
  }
  .analitics-view-table-save-button, .analitics-view-table-edit-button{
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 48px;
    max-width: fit-content;
    // box-sizing: 100%;
    box-sizing: border-box;
    width: 100%;
    // width: fit-content;
    padding: 12px;
    color: $gray-0;
    font-weight: var(--button-desktop-default-font-weight);
    font-size: var(--button-desktop-default-font-size);
    font-family: var(--button-desktop-default-font-family);
    font-style: normal;
    line-height: var(--button-desktop-default-line-height);
    letter-spacing: $letter-spacing-m0-28;
    background: $interactive-default;
    text-decoration: var(--button-desktop-default-text-decoration);
    border: 1px solid transparent;
    cursor: pointer;
    transition: all 0.12s ease-out;
    gap: 8px;
    background: $interactive-default;
    border-radius: 8px;
    &:hover, &:active, &.active{
      background: $interactive-hover;
      color: $gray-0;
    }
    &:disabled{
      opacity: 0.25;
      &:hover{
        background: $interactive-default;
      }
    }
  }
  .analitics-view-table-edit-button{
    color: #000000;
    background: transparent;
    padding: 0;
    &.add{
      &:hover, &:active, &.active{
        background: $heat-status-green;
        color: $gray-0;
      }
    }
    &.delete {
      &:hover, &:active, &.active{
        background: $heat-status-red;
        color: $gray-0;
      }
    }
  }
  .tableWrapper {
    overflow-x: auto;
  }
  .analitics-view-table-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
  }
  .resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
  }
