@import 'oelp-tokens/css/variables';

.editBar {
  display: flex;
  background: $surface-front;;
  min-width: 589px;
  min-height: 48px;
  border-radius: 8px;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 48px;
  min-width: fit-content;
  // box-sizing: 100%;
  box-sizing: border-box;
  width: 100%;
  // width: fit-content;
  padding: 12px;
  color: $gray-0;
  font-weight: var(--button-desktop-default-font-weight);
  font-size: var(--button-desktop-default-font-size);
  font-family: var(--button-desktop-default-font-family);
  font-style: normal;
  line-height: var(--button-desktop-default-line-height);
  letter-spacing: $letter-spacing-m0-28;
  background: $interactive-default;
  text-decoration: var(--button-desktop-default-text-decoration);
  border: 1px solid transparent;
  cursor: pointer;
  transition: all 0.12s ease-out;
  gap: 8px;


  &-uncolored {
    color: $gray-800;
    background: transparent;
    &:hover, &:active, &.active{
      color: $interactive-hover;
    }

    &:disabled{
      background: transparent;
      color: $gray-800;
      opacity: 0.25;
    }
  }
}
