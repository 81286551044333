@import 'oelp-tokens/css/variables';

.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    //  justify-content: space-between;
    // padding: 16px 0px 12px;
    // gap: 18px;

    // padding: 16px 16px 0;

    width: calc(23%);
    max-width: 450px;
    min-width: 350px;
    min-height: 518px;
    height: fit-content;

    border-radius: 8px;

    background-color: $surface-front;

    border-bottom: 12px solid $surface-front;

    cursor: pointer;
    border-bottom: 12px solid $surface-front;
}

.selected {
    border-bottom: 12px solid #689AFF;
}
