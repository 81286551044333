@import 'oelp-tokens/css/variables';

.sidebar {
  width: 100%;
  overflow: auto;
  background: var(--surface-front);
  display: flex;
  height: 100vh;
  background: #f2f3f5;
  flex-direction: column;
  max-width: 30vw;

  background-color: var(--surface-front);
}

.sidebar-header {
  display: flex;
  width: 100%;
  max-width: 536px;
  min-height: 104px;
}

.content-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;
  flex: 1;

  background-color: var(--surface-mid);

  .levels-cards {
    padding: 0 20px 32px 20px;
    align-content: flex-start;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: space-between;
    width: 100%;
  }
}
