@import 'oelp-tokens/css/variables';

.button-group-divider{
  display: flex;
  background-color: $gray-50;
  &.button-group-divider-col{
    height: 100%;
    min-height: 40px;
    width: 3px;
  }
  &.button-group-divider-row{
    min-width: 100%;
    height: 3px;
  }
}

