.map-overlay {
  filter: drop-shadow(4px 3px 2px rgb(0 0 0 / 0.4)) !important;
}

.layer {
  &-feature {
    position: relative;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    cursor: pointer !important;

    outline: none !important;

    transition: all 0.2s ease-out;

    &-title {
      min-width: max-content;
      pointer-events: none !important;

      &-p {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 100px;
        font-size: var(--caption-desktop-x3-25-weight-2-font-size);
        font-weight: var(--caption-desktop-x3-25-weight-2-font-weight);
        font-style: var(--caption-desktop-x3-25-weight-2-font-style);
        line-height: var(--caption-desktop-x3-25-weight-2-line-height);

        color: var(--gray-unchangeable-900);

        transform: translate(-50px, -36%);

        &-subheader {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
