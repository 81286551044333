@import 'oelp-tokens/css/variables';

.form {
    & input {
        border: 1px solid $gray-100;
    }

    & button {
        border: 1px solid $gray-100;
        // box-shadow: 0px 2px 12px $gray-100;
    }
}