@import 'oelp-tokens/css/variables';

.breadcrumbs{
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 6px 16px ;
  border-radius: 8px;
  background: $surface-front;
  box-shadow: 0 2px 12px rgba(5, 18, 70, 0.06);
}

.w-fill{
  max-width: 100%;
}
.w-content{
  max-width: fit-content;
}

.breadcrumb-item-span, .breadcrumb-divider {
  color: $gray-800;
  white-space: nowrap;
  font-family: var(--text-desktop-x4-weight-3-font-family);
  font-weight: var(--text-desktop-x4-weight-3-font-weight);
  line-height: var(--text-desktop-x4-weight-3-line-height);
  font-size: var(--text-desktop-x4-weight-3-font-size);
  letter-spacing: var(--text-desktop-x4-weight-3-letter-spacing);
  text-decoration: var(--text-desktop-x4-weight-3-text-decoration);
}


.breadcrumb-item-link {
  font-family: var(--button-desktop-default-font-family);
  font-weight: var(--button-desktop-default-font-weight);
  line-height: var(--button-desktop-default-line-height);
  font-size: var(--button-desktop-default-font-size);
  letter-spacing: var(--button-desktop-default-letter-spacing);
  text-decoration: var(--button-desktop-default-text-decoration);
  white-space: nowrap;
  color: $gray-900;
}

.breadcrumb-divider{
  color: $gray-900;
}
