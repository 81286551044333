@import 'oelp-tokens/css/variables';
.content-wrapper{
  display: flex;
  flex: 1;
  // z-index: 9999;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  &-start{
    justify-content: flex-start;
    &-button {
      max-width: 112px;
      box-shadow: none!important;
      border: 1px solid $gray-100;
    }
  }

  @media all and (max-width: 785px) {
    max-width: 20vw;
  }
}

.right-btns-wrapper {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 20px;
  flex: 1;
  .view-switcher-wrapper, .view-switcher-btn-wrapper {
    max-width: fit-content;
    &.view-switcher-list-view-bs {
      box-shadow: 0 0 0 1px $gray-100;
      border-radius: 8px;
      & > button {
        box-shadow: none;
      }
    }
  }
  &.contentStart{
    justify-content: start;
  }

  &.contentEnd {
    justify-content: end;
  }
}