@import 'oelp-tokens/css/variables';

.input-field {
    box-sizing: border-box;
    &-label {
        width: 100%;
        color: $gray-400;
        font-weight: normal;
        font-size: $font-size-x4;
        font-family: $font-families-main;
        font-style: normal;
        line-height: 16px;

        &-text {
            display: inline-block;
            margin-bottom: 4px;
            padding-left: 8px;
            transition: all 0.12s ease-out;
        }
        &:last-of-type {
            padding-bottom: 32px;
        }
        &:nth-of-type(n + 2) {
            padding-top: 12px;
        }

        &.error {
            color: $validation-error-main;
        }
    }


    &-input {
        box-sizing: border-box;
        width: 100%;
        padding: 16px;
        color: $gray-800;
        font-weight: normal;
        font-size: $font-size-x4;
        font-family: $font-families-main;
        font-style: normal;
        line-height: 18px;
        letter-spacing: -0.28px;
        border: 1.4px solid $gray-0;
        border-radius: 8px;
        outline: none;
        transition: all 0.12s ease-out;

      &-wrapper {
            position: relative;
        }

        &-icon-password {
            position: absolute;
            top: 0;
            right: 16px;
            width: 24px;
            height: 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            cursor: pointer;

            &-show {
                background-image: url(./icons/FormPasswordEyeHide.svg);
            }
            &-hide {
                background-image: url(./icons/FormPasswordEyeShow.svg);
            }
        }

        //&::placeholder {
        //    color: $gray-0;
        //}

        &.error {
            border: 1.4px solid $validation-error-main;
        }

        &.warning {
            border: 1.4px solid $validation-warning-main;
        }

        .error {
            border: 1.4px solid $validation-error-main;
        }

        .warning {
            border: 1.4px solid $validation-warning-main;
        }

    }
}
