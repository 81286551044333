@import '/node_modules/leaflet.markercluster/dist/MarkerCluster.Default.css';
@import '/node_modules/leaflet.markercluster/dist/MarkerCluster.css';
@import '/node_modules/react-leaflet-markercluster/dist/styles.min.css';
@import 'oelp-tokens/css/variables';

.marker-cluster-custom {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $surface-front;
  text-align: center;
  border-style: solid;
  border-width: 4px;
  border-radius: 50%;

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 32px;
    height: 32px;
    border-radius: 15px;
    font-size: var(--subheader-desktop-weight-2-font-size);
  }

  &.heat-veryBad {
    border-color: $heat-status-red-alpha;
    div {
      background-color: $heat-status-red;
    }
  }
  &.heat-badly {
    border-color: $heat-status-red-alpha;
    div {
      background-color: $heat-status-red;
    }
  }
  &.heat-satisfactory {
    border-color: $heat-status-yellow-alpha;
    div {
      background-color: $heat-status-yellow;
    }
  }
  &.heat-good {
    border-color: $heat-status-green-alpha;
    div {
      background-color: $heat-status-green;
    }
  }
  &.heat-perfectly {
    border-color: $heat-status-green-alpha;
    div {
      background-color: $heat-status-green;
    }
  }
  &.heat-gray {
    border-color: lightgray;
    div {
      background-color: gray;
    }
  }

  &.macro-gray {
    border-color: $heat-status-blue-alpha;
    div {
      background-color: $heat-status-blue;
    }
  }

  //&.macro-blue{
  //    border-color: $macro-region-blue-alpha;
  //    div {
  //        background-color: $macro-region-blue;
  //    }
  //}
  //&.macro-pink{
  //    border-color: $macro-region-pink-alpha;
  //    div {
  //        background-color: $macro-region-pink;
  //    }
  //}
  //
  //&.macro-orange{
  //    border-color: $macro-region-orange-alpha;
  //    div {
  //        background-color:$macro-region-orange;
  //    }
  //}
  //
  //&.macro-darkGreen {
  //    border-color: $macro-region-dark-green-alpha;
  //    div {
  //        background-color:$macro-region-dark-green;
  //    }
  //}

  &.macro-lightGreen {
    border-color: rgba(93, 208, 1, 0.6);
    div {
      background-color: #5dd001;
    }
  }
  &.macro-lightGreen10 {
    border-color: rgba(77, 225, 17, 0.6);
    div {
      background-color: #4de111;
    }
  }
  &.macro-lightGreen9 {
    border-color: rgba(156, 225, 17, 0.6);
    div {
      background-color: #9ce111;
    }
  }
  &.macro-lightGreen8 {
    border-color: rgba(197, 225, 17, 0.6);
    div {
      background-color: #c5e111;
    }
  }
  &.macro-pickleGreen {
    border-color: rgba(197, 176, 11, 0.6);
    div {
      background-color: #c5b00b;
    }
  }

  &.macro-brown {
    border-color: rgba(231, 60, 0, 0.6);
    div {
      background-color: #e73c00;
    }
  }

  &.macro-gazprom {
    border-color: rgba(0, 145, 254, 0.6) !important;
    div {
      background-color: #0091fe !important;
    }
  }
}

.marker-cluster-custom.subsidiaries {
  background-color: #0d47a1;
}

.marker-cluster-custom.departments {
  background-color: #1976d2;
}

.marker-cluster-custom.worships {
  background-color: #2196f3;
}

.marker-cluster-custom.schools {
  background-color: #64b5f6;
}

.marker-cluster-custom.sports {
  background-color: #4fc3f7;
}

.marker-cluster-custom.medicines {
  background-color: #03a9f4;
}

.marker-cluster-custom.leisure {
  background-color: #0288d1;
}
