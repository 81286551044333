@import 'oelp-tokens/css/variables';

.marker-circle-base {
  position: relative;
  width: 56px;
  height: 56px;
  color: $surface-front;
  font-weight: 600;
  font-size: $font-size-x4;
  line-height: 14px;
  letter-spacing: -0.4px;
  text-align: center;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  transition: all 0.12s ease-out;
  > span:hover {
    color: var(--hoveredcolor) !important;
    transition: all 0.12s ease-out;
  }

  &:hover {
    > span {
      color: var(--hoveredcolor) !important;
      transition: all 0.12s ease-out;
    }
    transition: all 0.12s ease-out;
    background-color: var(--hoveredcolor) !important;
  }

  &.active-marker {
    .marker-sphere-corner {
      position: absolute;
      bottom: -60%;
      z-index: -1;
      width: 0;
      height: 0;
      background: none;
      content: '';
    }
  }
}

.marker-gazprom-base {
  position: relative;
  left: -20px;
  width: 56px;
  color: $surface-front;
  font-weight: 600;
  font-size: $font-size-x4;
  line-height: 14px;
  letter-spacing: -0.4px;
  text-align: center;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  transition: all 0.12s ease-out;

  & > .marker-gazprom-body {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-right: 40px;
    width: 56px;
    height: 56px;

    & > .marker-gazprom-sphere {
      display: flex;
      position: absolute;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 1px solid #ffff01;
      background-color: #0091fe;
      transition: all 0.12s ease-in-out;
    }

    & > .marker-gazprom-sphere-active {
      display: flex;
      position: absolute;
      align-items: center;
      bottom: 29px;
      width: 56px;
      height: 56px;
      border: none;
      border-radius: 50%;
      background-color: #0091fe;
      transition: all 0.12s ease-in-out;
    }

    & > .marker-sphere-corner {
      position: absolute;
      bottom: 5px;
      border: 20px solid transparent;
      border-top: 40px solid #0091fe;
      width: 21px;
      height: 0px;
      border: 10px solid transparent;
      border-top: 17px solid #0091fe;
    }
  }

  &:hover {
    & > .marker-gazprom-body {
      & > .marker-gazprom-sphere {
        bottom: 29px;

        width: 56px;
        height: 56px;

        border: none;
      }
    }
  }
}

.marker-sphere {
  margin: -12px 0 0 -22px;
  &.active {
    filter: drop-shadow(0px 2px 12px rgba(2, 8, 32, 0.12));
  }

  width: 56px;
  height: 56px;
}

.marker-sphere-text {
  position: absolute;

  top: 50%;
  left: 50%;
  margin: 0 -50% 0 0;
  transform: translate(-50%, -50%);
}
.marker-sphere-star {
  position: absolute;
  top: 0;
  right: 0.5px;
  width: 13px;
  height: 13px;
  margin-top: -3.5px;
  background: url('icons/map-marker-star.svg') center center no-repeat;
  background-size: 100%;
}

.leaflet {
  &-tooltip {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 12px 8px 10px;
    color: $gray-800;
    cursor: pointer;
    font-style: normal;
    line-height: 18px;
    letter-spacing: -0.28px;
    white-space: pre;
    background: $surface-front;
    border-radius: 8px;
    box-shadow: none;
    filter: drop-shadow(0px 2px 12px rgba(2, 8, 32, 0.12));
    transition: opacity 0.12s ease-out;
    &-content {
      display: flex;
      flex-direction: column;
      font-family: $font-families-main;
      &-name {
        font-size: $font-size-x4;
        font-weight: $font-weights-medium;
      }
      &-place {
        font-size: $font-size-x3;
        font-weight: $font-weights-regular;
        margin-top: 5px;
      }
    }

    img {
      &.left {
        margin-right: 8px;
      }
      &.right {
        margin-left: 8px;
      }
    }

    &-left {
      margin-left: -15px !important;

      &::before {
        top: 0;
        right: -17px;
        width: 18px;
        height: 100%;
        margin: 0;
        background-image: url('icons/popup-arrow.svg');
        background-repeat: no-repeat;
        background-position: center;
        border: none;
        transform: rotate(-90deg);
      }
    }
    &-right {
      margin-left: 15px !important;

      &::before {
        top: 0;
        left: -17px;
        width: 18px;
        height: 100%;
        margin: 0;
        background-image: url('icons/popup-arrow.svg');
        background-repeat: no-repeat;
        background-position: center;
        border: none;
        transform: rotate(90deg);
      }
    }
  }
  .obj-name {
    background-color: rgba(0, 0, 0, 0) !important;
    color: pink !important;
  }
}
