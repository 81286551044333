.footer {

  &-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: none;

    &-menu {
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      width: 100%;
      gap: 40px;
    }

    &-logo {
      display: flex;
      position: relative;
      right: 8%;
      height: 46px;

      @media all and (min-width: 1024px) {
        right: 5%;
      }
    }
  }
}
