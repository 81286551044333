@import 'oelp-tokens/css/variables';
.container {
  display: flex;
  width: 100%;
  max-height: 95vh;
}

.editBarWrapper {
  width: fit-content;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: flex-start;
  position: fixed;
  z-index: 16;
  top: 40px;
}

.wrapper {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 100px 40px;

  background-color: $surface-back;
  //z-index: 1500;
  overflow-x: scroll;
  overflow-y: auto;
}
