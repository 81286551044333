@import 'oelp-tokens/css/variables';
.container {
  display: flex;
  height: 100vh;
  background: $surface-mid;
  flex-direction: column;
  font-family: $font-families-main;
  // padding: 20px;
  width: 100%;
  max-width: 490px;
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  position: relative;
  gap: 16px;

  @media all and (min-width: 2880px) {
    max-width: 490px;
  }

  @media all and (max-width: 1800px) {
    max-width: 490px;
  }
}

.container::-webkit-scrollbar {
  display: none;
}
