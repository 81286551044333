@import 'oelp-tokens/css/variables';

.container {
  margin-bottom: 8px;
  background-color: $surface-front;
  border-radius: 8px;

  &-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0;
    padding: 12px 12px 12px 20px;
    font-family: var(--button-desktop-lite-font-family);
    font-weight: var(--button-desktop-lite-font-weight);
    line-height: var(--button-desktop-lite-line-height);
    font-size: var(--button-desktop-lite-font-size);

    border-radius: 8px;

    cursor: pointer;

    &:hover {
      background-color: $interactive-light-hover;
    }

    &-arrow {
      transition: all 0.3s ease-out;
      transform: rotate(0);

      &_opened {
        transform: rotate(0.5turn);
      }
    }
  }

  &-list {
    margin-bottom: 0;
    margin-top: 0;
    max-height: 0;
    padding-left: 1rem;
    overflow: hidden;

    transition: all 0.5s ease-out;

    &_opened {
      margin-top: 8px;
      max-height: 800px;
    }
  }
}
