@import 'oelp-tokens/css/variables';

.modal {

  &-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    &-background {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10005;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4);
    }

    &-window {
      position: relative;
      z-index: 10010;
    }

    &-open {
      position: absolute;
      z-index: 10010;
      display: flex;
      //transition: bottom 2s ease-in 1s;
      animation: top 0.2s ease-out;
      bottom: 40px;
    }

    &-close {
      position: absolute;
      z-index: 10010;
      display: flex;
      //transition: bottom 2s ease-in 1s;
      animation: bottom 0.1s linear;
      bottom: -100px;
    }
  }
}

.object-delete {

  &-wrapper {
    padding: 20px 24px;
    background: $surface-front;
    border-radius: 16px;

    &-head {
      display: flex;
      flex-direction: column;

      &-title {
        font-family: var(--title-desktop-h3-weight-1-font-family);
        font-size: var(--title-desktop-h3-weight-1-font-size);
        font-weight: var(--title-desktop-h3-weight-1-font-weight);
        line-height: var(--title-desktop-h3-weight-1-line-height);
      }

      &-text {
        font-family: var(--paragraph-desktop-x4-weight-3-font-family);
        font-size: var(--paragraph-desktop-x4-weight-3-font-size);
        font-weight: var(--paragraph-desktop-x4-weight-3-font-weight);
        line-height: var(--paragraph-desktop-x4-weight-3-line-height);
        margin-top: 8px;
        &-label {
          max-width: 380px;
          word-wrap: break-word;
        }
      }
    }
    &-buttons {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-top: 32px;
      gap: 16px;

      &-close {
        max-width: 132px;
      }

      &-delete {
        max-width: 123px;
      }
    }
  }
}

@keyframes top {
  0% {
    bottom: -100px;
  }
  30% {
    bottom: -40px;
  }
  60% {
    bottom: 0;
  }
  100% {
    bottom: 40px;
  }
}

@keyframes bottom {
  0% {
    bottom: 40px;
  }
  30% {
    bottom: 0;
  }
  60% {
    bottom: -40px;
  }
  100% {
    bottom: -100px;
  }
}
