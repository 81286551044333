@import 'oelp-tokens/css/variables';

.select {
  &-wrapper {
    position: relative;
    width: 100%;
  }

  &-input {
    box-sizing: border-box;
    width: 100%;
    padding: 16px;
    color: $gray-800;
    font-weight: normal;
    font-size: $font-size-x4;
    font-family: $font-families-main;
    font-style: normal;
    line-height: 18px;
    letter-spacing: -0.28px;
    border: 1.4px solid $gray-0;
    border-radius: 8px;
    outline: none;
    cursor: text;
    transition: all 0.12s ease-out;


    //&::placeholder {
    //  //color: $gray-0;
    //}

    &.error {
      border: 1.4px solid $validation-error-main;
    }


  }

  &-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
  }
}
