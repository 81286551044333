@import 'oelp-tokens/css/variables';

.container {
  display: inline-block;
  min-width: 107px;
  height: fit-content;

  overflow: hidden;

  width: calc(50% - 8px);

  background-color: rgba(0, 0, 0, 0);

  box-sizing: border-box;

  flex-grow: 1;

  &-tooltip {
    width: 96%;
  }
}

.label {
  position: relative;
  display: flex;
  gap: 8px;
  // justify-content: space-between;
  align-items: center;

  min-width: 107px;
  height: fit-content;

  padding: 12px 12px 12px 20px;

  background-color: $surface-front;

  border-radius: 8px;

  cursor: pointer;

  font-family: var(--button-desktop-lite-font-family);
  font-weight: var(--button-desktop-lite-font-weight);
  line-height: var(--button-desktop-lite-line-height);
  font-size: var(--button-desktop-lite-font-size);

  white-space: wrap;
  text-overflow: ellipsis;

  overflow: hidden;

  transition: all 0.12s ease-out;
  z-index: 1;

  &-checkbox-icon {
    display: inline-block;
    height: 20px;
    width: 20px;
    margin-right: 4px;
    background: #fff;
    border: 2px var(--gray-100) solid;
    border-radius: 2px;

    transition: all 0.12s ease-in-out;

    &-active {
      border-color: var(--interactive-default);
      background: var(--interactive-default);
    }
  }

  &:hover {
    background-color: var(--interactive-light-hover);
  }

  & p {
    font-family: var(--button-desktop-lite-font-size);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-left-border {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;

    width: 8px;
  }

  &-input-button {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &-input-checkbox {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  &-checkmark-button,
  &-checkmark-checkbox {
    position: absolute;
    display: inline-block;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }

  &-input-button:checked ~ &-checkmark-button {
    background-color: var(--interactive-default);
    z-index: -1;
  }

  // &-input-checkbox:checked ~ &-checkmark-checkbox {
  //   background-color: var(--interactive-light-hover);
  //   z-index: -1;
  // }
}

.activeLabel {
  color: var(--gray-0);
}

:export {
  macro_region_pink: $macro-region-pink;
  macro_region_pink-alpha: $macro-region-pink-alpha;
  macro_region_orange: $macro-region-orange;
  macro_region_orange_alpha: $macro-region-orange-alpha;
  macro_region_dark_green: $macro-region-dark-green;
  macro_region_dark_green_alpha: $macro-region-dark-green-alpha;
  macro_region_blue: $macro-region-blue;
  macro_region_blue_alpha: $macro-region-blue-alpha;
  macro_region_outline_pink: $macro-region-outline-pink;
  macro_region_outline_orange: $macro-region-outline-orange;
  macro_region_outline_dark_green: $macro-region-outline-dark-green;
  macro_region_outline_blue: $macro-region-outline-blue;
}
