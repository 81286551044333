@import 'oelp-tokens/css/variables';


@mixin p-weight-options($font-family, $font-weight, $line-height, $font-size, $letter-spacing) {
    font-family: $font-family;
    font-weight: $font-weight;
    line-height: $line-height;
    font-size: $font-size;
    letter-spacing: $letter-spacing
}

.p {
    &-x4 {
        &-weight {
            &-1 {
                @include p-weight-options(
                    var(--paragraph-desktop-x4-weight-1-font-family),
                    var(--paragraph-desktop-x4-weight-1-font-weight),
                    var(--paragraph-desktop-x4-weight-1-line-height),
                    var(--paragraph-desktop-x4-weight-2-font-size),
                    var(--paragraph-desktop-x4-weight-1-letter-spacing)
                    )
            }
            &-2 {
                @include p-weight-options(
                    var(--paragraph-desktop-x4-weight-2-font-family),
                    var(--paragraph-desktop-x4-weight-2-font-weight),
                    var(--paragraph-desktop-x4-weight-2-line-height),
                    var(--paragraph-desktop-x4-weight-2-font-size),
                    var(--paragraph-desktop-x4-weight-2-letter-spacing)
                    )
            }
            &-3 {
                @include p-weight-options(
                    var(--paragraph-desktop-x4-weight-3-font-family),
                    var(--paragraph-desktop-x4-weight-3-font-weight),
                    var(--paragraph-desktop-x4-weight-3-line-height),
                    var(--paragraph-desktop-x4-weight-3-font-size),
                    var(--paragraph-desktop-x4-weight-3-letter-spacing)
                    )
            }
        }
    }

    &-x3-5 {
        &-weight {
            &-1 {
                @include p-weight-options(
                    var(--paragraph-desktop-x3-5-weight-1-font-family),
                    var(--paragraph-desktop-x3-5-weight-1-font-weight),
                    var(--paragraph-desktop-x3-5-weight-1-line-height),
                    var(--paragraph-desktop-x3-5-weight-2-font-size),
                    var(--paragraph-desktop-x3-5-weight-1-letter-spacing)
                    )
            }
            &-2 {
                @include p-weight-options(
                    var(--paragraph-desktop-x3-5-weight-2-font-family),
                    var(--paragraph-desktop-x3-5-weight-2-font-weight),
                    var(--paragraph-desktop-x3-5-weight-2-line-height),
                    var(--paragraph-desktop-x3-5-weight-2-font-size),
                    var(--paragraph-desktop-x3-5-weight-2-letter-spacing)
                    )
            }
            &-3 {
                @include p-weight-options(
                    var(--paragraph-desktop-x3-5-weight-3-font-family),
                    var(--paragraph-desktop-x3-5-weight-3-font-weight),
                    var(--paragraph-desktop-x3-5-weight-3-line-height),
                    var(--paragraph-desktop-x3-5-weight-3-font-size),
                    var(--paragraph-desktop-x3-5-weight-3-letter-spacing)
                    )
            }
        }
    }
}

