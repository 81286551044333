@import 'oelp-tokens/css/variables';

.container {
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: 100%;
  min-width: fit-content;

  .investment-attractivness {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    min-width: 20px;

    font-family: var(--subheader-desktop-weight-2-font-family);
    font-weight: var(--subheader-desktop-weight-2-font-weight);
    font-size: var(--subheader-desktop-weight-2-font-size);
    letter-spacing: var(--subheader-desktop-weight-2-letter-spacing);
    text-decoration: var(--subheader-desktop-weight-2-text-decoration);


    &.attractivness-high {
      box-shadow: 0 0 0 1px #5DD001;
      color: #5DD001
    }

    &.attractivness-middle {
      box-shadow: 0 0 0 1px #C5B00B;
      color: #C5B00B
    }

    &.attractivness-low {
      box-shadow: 0 0 0 1px #E73C00;
      color:  #E73C00
    }
  }

  .attractivness-text {
    color: var(--gray-900);
    margin: 0;
  }
}

