@import 'oelp-tokens/css/variables';
@import 'src/fonts/icons-font/style.scss';

.input-field {
  &-label {
    width: 100%;
    color: $gray-400;
    font-weight: normal;
    font-size: $font-size-x4;
    font-family: $font-families-main;
    font-style: normal;
    line-height: 16px;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 2px 12px rgba(5, 18, 70, 0.06);

    &.label-list-view-bs {
      box-shadow: 0 0 0 1px $gray-100;
      border-radius: 8px;
    }

    .input-suggestion {
      position: absolute;
      display: inline-block;
      white-space: nowrap;
      top: 14.2px;
      left: 56.2px;
      color: $gray-800;
      font-weight: normal;
      font-size: $font-size-x4;
      font-family: $font-families-main;
      font-style: normal;
      line-height: 18px;
      letter-spacing: -0.28px;
      opacity: 0.18;
      min-width: 200px;
      width: 100%;
      max-width: 70px;
      word-wrap: break-word;
      overflow: hidden;
    }
    //&-text {
    //    display: inline-block;
    //    margin-bottom: 4px;
    //    padding-left: 8px;
    //    transition: all 0.12s ease-out;
    //
    //    &.error {
    //        color: $validation-error-main;
    //    }
    //}
    //&:last-of-type {
    //    padding-bottom: 32px;
    //}
    //&:nth-of-type(n + 2) {
    //    padding-top: 12px;
    //}
  }

  &-invalid-field {
    display: inline-block;
    margin-top: 4px;
    padding-left: 8px;
    color: $gray-800;
    font-weight: normal;
    font-size: $font-size-x4;
    font-family: $font-families-main;
    font-style: normal;
    line-height: 18px;
    letter-spacing: -0.28px;
  }

  &-input {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    min-height: 48px;
    max-height: 48px;
    padding: 12px 40px;
    color: $gray-800;
    font-weight: normal;
    font-size: $font-size-x4;
    font-family: $font-families-main;
    font-style: normal;
    line-height: 18px;
    letter-spacing: -0.28px;
    border: 1.4px solid $gray-0;
    border-radius: 8px;
    outline: none;
    transition: all 0.12s ease-out;
    cursor: text;

    &-icon-search {
      position: absolute;
      top: 10px;
      left: 16px;
      color: #000;
    }

    &-filter-tag {
      position: absolute;
      top: 4px;
      left: 60px;
    }

    &-close-btn {
      position: absolute;
      right: 0;
      top: 6px;
    }

    &::placeholder {
      color: $gray-200;
    }

    &.error {
      border: 1.4px solid $validation-error-main;
    }
  }
}
