.map-view {
    width: 100%;
    height: 100%;


}

.editor-cursor-enabled{
        cursor:crosshair;
    }

.bottom-left-controls {
    position: absolute;
    bottom: 16px;
    left: 16px;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: flex-start;


    @media all and (min-width: 785px) {
        bottom: 40px;
        left: 40px;
    }
}
