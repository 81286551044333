@import 'oelp-tokens/css/variables';
@import '../../mixin.scss';

.date-picker {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  transition: all 0.1s ease-out;
  border-radius: 4px;
  width: 100%;

  @include greater-mobile {
    min-width: 300px;
    padding: 12px;
  }

  > div {
    width: 100%;
    .react-datepicker {
      width: 100%;
    }
    .react-datepicker__month-container {
      width: 100%;
      margin: 0;
    }
    .react-datepicker__day-names,
    .react-datepicker__week {
      white-space: nowrap;
      display: flex;
      text-transform: capitalize;
    }
  }
  &-header {
    width: 100%;
    &-title {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      width: 100%;
      display: flex;
      align-items: center;
      letter-spacing: -0.3px;
      justify-content: space-between;
      text-align: center;
      margin-bottom: 40px;
    }

    &-content {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      justify-content: space-between;
      display: flex;
      align-items: center;
      letter-spacing: -0.24px;
      margin-bottom: 20px;
      text-transform: capitalize;
    }
  }
}
